

.main-wrapper {
  background: url("../../../../assets/images/student/student-sct-bg.png") no-repeat;
  background-size: 100% 100%;
  height: 100%;
  position: relative;
  .main-wrapper-scrollbar {
    height: 100%;
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    .wrapper-list {
      .top-title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: 48px;
        color: #35c773;
        padding-top: 20px;
        letter-spacing: 4px;
        font-weight: bold;
        position: relative;
        .title {
          position: absolute;
          top: 0;
        }
        &:before {
          content: url("../../../../assets/images/student/line-left.png");
          margin-right: 15px;
        }
        &:after {
          content: url("../../../../assets/images/student/line-right.png");
          margin-left: 15px;
        }
        .backBtn{
          position: absolute;
          right: 40px;
          top: 0;
        }
      }
      .time-item {
        /*position: absolute;*/
        /*top: 180px;*/
        margin-top: 80px;
        width: 100%;
        display: flex;
        justify-content: center;

        .time-item-list {
          display: flex;
          height: 80px;
          line-height: 80px;
          background: #39B678;

          .time-title-left {
            flex: 1;
            display: flex;
            border: 6px solid #61a885;
            background: #DDFDF2;

            .comma {
              color: #31A588;
              font-size: 66px;
              letter-spacing: 18px;
              text-align: center;
              line-height: 33px;
              display: inline-block;
            }

            .item {
              flex: 1;
              font-size: 24px;
              padding: 0 40px;
              color: #31A588;
              position: relative;

              &:after {
                content: '';
                height: 40px;
                width: 3px;
                background: #31A588;
                position: absolute;
                top: 14px;
                right: 10px;
              }

              &:before {
                content: '';
                height: 40px;
                width: 3px;
                background: #31A588;
                position: absolute;
                top: 14px;
                left: 10px;
              }
            }
          }

          .start-tex {
            width: 120px;
            font-size: 24px;
            color: #fff;
            cursor: pointer;
            text-align: center;
          }
        }

      }
    }
    .mission-wrapper {
      width: 100%;
      .mission-title {
        width: 100%;
        height: 80px;
        text-align: center;
        font-size: 48px;
        font-style: italic;
        font-weight: 600;
        position: relative;
        margin-top: 40px;
        .line {
          position: absolute;
          top: 40px;
          left: calc(50% - 150px);
          width: 300px;
          height: 20px;
          background: #FEDB2E;
        }
        .mission-title-text {
          position: inherit;
          /*left: calc(50% - 100px);*/
          text-align: center;
        }
      }

      .task-item {
        margin-top: 20px;
        .task-bg {
          color: #fff;
          position: relative;
          margin-left: 50px;
          .num-title {
            font-size: 34px;
            position: absolute;
            top: -26px;
            left: 50px;

            em {
              font-style: normal;
            }
          }
          .title {
            color: rgb(255, 255, 255);
            font-size: 30px;
            position: absolute;
            top: -22px;
            left: 126px;
          }
        }
      }
    }
  }


  .task-content {
    background: #DDFDF2;
    padding: 20px 20px 20px 50px;
    margin: -20px 20px;
    ::v-deep p img{
      max-width: 100%;
      max-height: 100%;
    }
    .text {
      ::v-deep p span {
        background-color: transparent !important;
      }
      ::v-deep table {
        tr {
          height: 50px;
          line-height: 50px;
          td {
            border: 1px solid #D2D2D2;
            border-right: 2px solid #fff;
            text-align: center;
            font-size: 14px;
            color: #333;
            &:first-child {
              border-right: 2px solid #fff;
            }
            &:last-child {
              border-right:  1px solid #D2D2D2;
            }
          }
          &:nth-child(2n) {
            td {
              background: #F2F9FA;
            }
          }
          &:nth-child(2n-1) {
            td {
              background: #C4F2E4;
            }
          }
        }
      }
    }
    ::v-deep .el-table--medium td, ::v-deep .el-table--medium th {
      border-right: 2px solid #fff;
    }
    ::v-deep .el-table tr {
      &:nth-child(even) {
        td {
          background:#C4F2E4;
          border-bottom: 2px solid #fff
        }
      }
      &:nth-child(odd) {
        td {
          background:#F2F9FA ;
          border-bottom: 2px solid #fff
        }
      }
    }
  }
}
